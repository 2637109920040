<template>
    <div class="pt-r box">
        <div class="bgbox pt-a"></div>
        <navigation :title="state.title" class="ritem mb-42"></navigation>
        <el-table :data="state.Notice" class="mb-20 ritem" v-loading="state.loading"
            :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }">
            <el-table-column :align="'center'" width="55" />
            <el-table-column prop="id" label="订单号" />
            <el-table-column :align="'center'" prop="chnname" label="认证经办人" />
            <el-table-column :align="'center'" label="订单状态">
                <template #default="scope">
                    <div class="dp-fc contractStatus" :class="'contractStatus'+scope.row.authenticationState">
                        <div class="w-6 h-6 icon mr-9"></div>
                        <div class="ta-l">{{ getauthenticationState(scope.row.authenticationState) }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :align="'center'" prop="createDate" label="发起日期" />
            <el-table-column :align="'center'" label="操作" width="220">
                <template #default="scope">
                    <div class="dp-fc butbox fw-w">
                        <div class="Editor_span" @click="showDetail(scope.row)">详情</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <Detailsdrawer ref="detailsRef" @reCertification="getDataAll()"></Detailsdrawer>
    </div>
</template>
<script setup>
import { reactive, ref, unref } from "vue";
import { useStore } from "vuex";
import navigation from "./components/navigation.vue";//导航条
import {getauthenticationState} from "@/utils/server/getcode.js"
import Detailsdrawer from "./components/record/details.vue"//详情
import { httpToken } from "@/utils/request";
import qs from "qs";
const store = useStore();
const detailsRef=ref();//详情弹框ref
const state = reactive({
    loading:false,//loading显示
    title:'',//标题
    Notice: [],//表格数据
});
const getDataAll=()=>{
    state.loading = true
    let json = [
        { column: "authentication_record.company_id", type: 'eq',value:store.state.realName.companyId },
        {column: "create_date", type: "orderByDesc"},
    ];
    httpToken({
        method: "post",
        url: '/admin/authenticationrecord/all',
        data: qs.stringify({
            paramData: JSON.stringify(json),
        }),
    }).then((res) => {
        state.loading = false
        state.Notice=res.data
    }).catch(err=>{
        state.loading = false
    })
}
getDataAll()
// 展示详情数据
const showDetail=(row)=>{
    unref(detailsRef).getDetails(row.id)
}
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
.box{
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
}
.bgbox{
    width: 100%;
    height: 715px;
    background: linear-gradient(180deg, rgba(238,199,116,0.09) 0%, rgba(238,199,116,0) 100%);
    z-index: 1;
}
.ritem{
    position: relative;
    z-index: 2;
}
::v-deep .el-table__row{
    position: relative;
}
::v-deep .el-table .el-table__cell{
    position: static;
}
::v-deep .contractStatus{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    .icon{
        background: #999999;
        border-radius: 50%;
    }
}
  // 认证状态0：基本信息认证中 1：基本信息认证完成 2：认证授权公函认证中3：认证授权公函认证完成4：对公账户验证中5：对公账户验证完成6：全部认证完成7：已撤销 8认证失败
::v-deep .contractStatus6{
    color: #52C41A;
    .icon{
        background: #52C41A;
    }
}
::v-deep .contractStatus0,.contractStatus1,.contractStatus2,.contractStatus3,.contractStatus4,.contractStatus5{
    color: #FA541C;
    .icon{
        background: #FA541C;
    }
}
::v-deep .contractStatus8{
    color: #FAAD14;
    .icon{
        background: #FAAD14;
    }
}
::v-deep .contractStatus7{
    color: #1890FF;
    .icon{
        background: #1890FF;
    }
}
</style>